function FreeSubscription () {
  function checkPhoneNumberFields () {
    $('#free_subscription_submit').on('click', function (event) {
      const phoneNumber = $('#user_phone_number').val()

      if (phoneNumber === '') {
        event.preventDefault()
        alert('Please enter a valid phone number!')
      }
    })
  }

  if (window.location.pathname.includes('subscription_tiers/free_subscription_form')) {
    checkPhoneNumberFields()
  }
}

export default FreeSubscription
